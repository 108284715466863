// import DataRelative from './pages/manager/dataRelative/index'
// import FunctionRelative from './pages/manager/functionRelative';
// import SignUp from './pages/manager/signUp';
// import PlanPage from './pages/user/planPage';
// import HistoryPage from './pages/user/historyPage';
import { Routes, Route,/*Navigate, Link,useParams,useLocation,, RouterProvider*/ } from "react-router-dom"
// import QueryPage from './pages/user/queryPage';
// import ResultPage from './pages/user/queryResult/indes';
// import './App.css';
import { Suspense } from 'react';
import publicRoutes from './routes/publicRoutes';
import adminRoutes from './routes/privateRoutes';
import AuthRoute from './routes/AuthRoute';


// const currentPage = publicRoutes.map(({ path, element, ...routes }) => (
//   <Route key={path} path={path} element={element} {...routes} />
// ));
// console.log(currentPage)

function App() {
  return (
    // <div>
    //   <Routes>
    //     <Route path='/' element={<Navigate to='/plan' />}></Route>
    //     <Route path='/plan' element={<PlanPage />}></Route>
    //     <Route path='/history_score' element={<HistoryPage />}></Route>
    //     <Route path='/query' element={<QueryPage />}></Route>
    //     <Route path='/result' element={<ResultPage />}></Route>
    //     <Route path='/managerSignUp' element={<SignUp></SignUp>}></Route>
    //     <Route path='/managerData' element={<DataRelative></DataRelative>}></Route>
    //     <Route path='/managerFunc' element={<FunctionRelative></FunctionRelative>}></Route>
    //   </Routes>
    // </div>
    <Suspense fallback={<div>Loading......</div>}>
      <Routes>
        {
          publicRoutes.map(
          ({path, element, ...routes}) => 
            <Route key={path} path={path} element={element} {...routes}/>
          )
        }
        {
          adminRoutes.map(({ path, element, backurl, role, ...routes }) => (
            <Route key={path} path={path} element={
              <AuthRoute role={role} backurl={backurl}>
                {element}
              </AuthRoute>
            } />
          ))
        }
      </Routes>
    </Suspense>
  )
}

export default App;
