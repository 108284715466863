import { lazy } from "react";

const DataRelative = lazy(() => import('../pages/manager/dataRelative/index'));
const FunctionRelative = lazy(() => import('../pages/manager/functionRelative'));

const adminRoutes = [
    {
        path: '/managerData',
        element: <DataRelative />,
        role: "admin",
        backurl: '/managerSignup',
        exact: true
    },
    {
        path: '/managerFunc',
        element: <FunctionRelative />,
        role: "admin",
        backurl: '/managerSignup',
        exact: true
    }
]

export default adminRoutes
