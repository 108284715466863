import { Navigate } from "react-router-dom"
import React from "react";

function AuthRoute(props) {
    const { role, backurl, children } = props;
    console.log(children);
    if (role === 'admin' && window.localStorage.getItem('token')) {
        return children
    } else {
        return <Navigate replace to={backurl} />
    }
}

export default AuthRoute