import { lazy } from "react";

const PlanPage = lazy(() => import('../pages/user/planPage'));
const HistoryPage = lazy(() => import('../pages/user/historyPage'));
const QueryPage = lazy(() => import('../pages/user/queryPage'));
const ResultPage = lazy(() => import('../pages/user/queryResult/indes'));
const SignUp = lazy(() => import('../pages/manager/signUp'));
const publicRoutes = [
    {
        path: '/',
        element: <PlanPage />,
        exact: true
    },
    {
        path: '/plan',
        element: <PlanPage />,
        exact: true
    },
    {
        path: '/history_score',
        element: <HistoryPage />,
        exact: true
    },
    {
        path: '/query',
        element: <QueryPage />,
        exact: true
    },
    {
        path: '/result',
        element: <ResultPage />,
        exact: true
    },
    {
        path: '/managerSignup',
        element: <SignUp />,
        exact: true
    }
]
export default publicRoutes;