import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import {  BrowserRouter } from 'react-router-dom';

// head中添加meta标签，禁止缓存
let meta = document.createElement('meta');
meta.httpEquiv = 'Cache-Control';
meta.content = 'no-cache, no-store, must-revalidate,max-age=0, post-check=0, pre-check=0';
document.getElementsByTagName('head')[0].appendChild(meta);
meta = document.createElement('meta');
meta.httpEquiv = 'Pragma';
meta.content = 'no-cache';
document.getElementsByTagName('head')[0].appendChild(meta);
meta = document.createElement('meta');
meta.httpEquiv = 'Expires';
meta.content = '0';
document.getElementsByTagName('head')[0].appendChild(meta);

// 清理缓存
function clearCache() {
  if (window.caches) {
    caches.keys().then((keys) => {
      keys.forEach((key) => {
        caches.delete(key);
      });
    });
  }
}
clearCache();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
